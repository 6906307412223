:root {
    --backgroundPages: rgb(250, 250, 250);
    --backgroundButton: #e86630;
    --backgroundButtonHover: #ee7342;
    --borderButton: #8d8da6;
    --borderButtonOpacity: rgba(141, 141, 166, 0.25);
    --borderSertificate: #4096ff;
    --white: #fff;
    --black: #000;
    --gosblue: #0d4cd3;
    --textActive: #e86630;
    --placeholderInputIMask: rgba(0, 0, 0, 0.3);
    --borderInputIMask: #d9d9d9;
    --colorInputI: #a1a1a1;
    --colorText: rgba(0, 0, 0, 0.88);
    --backgroundDisabled: rgba(0, 0, 0, 0.04);
    --colorActiveTab: #1677ff;
    --colorEmphasizedText: #1677ff;
    --color-error: #ff4d4f;
    --color-success: rgb(0, 169, 105);
    --color-black-35: rgba(0, 0, 0, 0.35);
    --color-black-45: rgba(0, 0, 0, 0.45);
    --color-black-02: rgba(0, 0, 0, 0.02);
    --color-black-15: rgba(0, 0, 0, 0.15);
    --color-link: rgba(22, 119, 255, 1);
    --color-primary-border-hover: #69b1ff;
    --control-item-bg-active: #e6f4ff;
    --color-primary-active: #0958d9;
    --bg-error-message: #fff1f0;
    --color-text-loader: #1d45e4;
    --PSB_base_orange: #ea5616;
    --PSB_base_orangehover: #ff773c;
    --PSB_base_orangepress: #d34d14;
    --colorBgContainerDisabled: #0000000a;
    --colorBorderDisabled: #00000026;
    --colorTextDisabled: #00000040;

    /* blur */
    --blur-10: 10px;

    /* border-radius */
    --border-radius-s: 8px;
    --border-radius-xs: 12px;

    /* outline */
    --outline-white: 2px solid var(--white);
    --outline-start-page: 1px solid var(--color-primary-border-hover);

    /* font size */
    --small-3: 12px;
    --small-2: 14px;
    --small-1: 15px;
    --small: 16px;
    --medium: 18px;
    --medium-m: 20px;
    --medium-l: 24px;
    --medium-x: 30px;
    --large: 32px;
    --large-m: 38px;
    --large-x: 40px;

    /* font weight */
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;
    --font-extra-bold: 800;
    --font-ultra-bold: 900;

    /* line-height */
    --line-height-small-3: 20px;
    --line-height-small-2: 36px;
    --line-height-small-1: 49px;
    --line-height-medium: 130%;

    /* padding, margin */
    --gap-5xs: 1px;
    --gap-4xs: 2px;
    --gap-3xs: 4px;
    --gap-2xs: 6px;
    --gap-xs-3: 8px;
    --gap-xs-2: 10px;
    --gap-xs-1: 11px;
    --gap-s: 12px;
    --gap-m-1: 15px;
    --gap-m: 16px;
    --gap-l: 20px;
    --gap-xl: 24px;
    --gap-2xl: 32px;
    --gap-3xl: 40px;
    --gap-4xl: 48px;
    --gap-4xl-2: 56px;
    --gap-5xl: 64px;
    --gap-6xl: 72px;
    --gap-7xl: 96px;
    --gap-8xl: 128px;

    /* padding, margin negative */
    --gap-4xs-neg: -2px;
    --gap-3xs-neg: -4px;
    --gap-2xs-neg: -6px;
    --gap-xs-neg: -8px;
    --gap-s-neg: -12px;
    --gap-m-neg: -16px;
    --gap-l-neg: -20px;
    --gap-xl-neg: -24px;
    --gap-2xl-neg: -32px;
    --gap-3xl-neg: -40px;
    --gap-4xl-neg: -48px;
    --gap-5xl-neg: -64px;
    --gap-6xl-neg: -72px;
    --gap-7xl-neg: -96px;
    --gap-8xl-neg: -128px;

    /* gap */
    --gap-20: 20px;
    --gap-24: 24px;
    --gap-30: 30px;
    --gap-36: 36px;
    --gap-40: 40px;

    /* width */
    --width-for-column-220: 220px;
    --width-for-column-200: 200px;
    --width-for-column-150: 150px;
    --grid-template-columns-width-mobile: 280px;
    --grid-template-columns-width-desktop: 360px;
    --button-width-desktop: 370px;
    --grid-template-columns-width-big: 334px;
    --button-width-yes: 100px;
    --button-width-mobile: 280px;
    --width-desktop: 1440px;
    --card-not-found-width: 594px;
    --text-link-width: 480px;
    --text-upload-width: 310px;
    --enter-block-width: 796px;
    --empty-block-width: 510px;

    /* height */
    --header-logo-desktop: 40px;
    --header-height-desktop: 80px;
    --button-height: 50px;
    --enter-page-button-height: 32px;
    --max-certificates-height: 260px;
    --select-certificate-height: 146px;
    --enter-spinner-height: 158px;
    --auth-spinner-height: 80px;

    /* transitions */
    --transition-duration-default: 0.2s;

    /* border */
    --border-radius-m: 8px;
    --border-radius-lg: 12px;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    font: inherit;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
}

input,
button,
textarea,
select {
    font: inherit;
    border: none;
}

input {
    outline: none;
    outline-offset: 0;
}

input:active,
input:hover,
input:focus {
    outline: none;
    outline-offset: 0;
}

button {
    cursor: pointer;
    padding: 0;
}

button:focus {
    outline: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    display: block;
}
