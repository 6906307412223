.menu {
    height: 100%;
    background: var(--white);
    overflow-y: auto;
    overflow-x: hidden;
}

aside.sidebar {
    height: 100%;
    background: var(--white);
}
