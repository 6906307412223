.certActions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modal :global(.ant-modal-header) {
    margin-bottom: 0;
}

:global(.ant-modal).modal :global(.ant-modal-close) {
    top: 20px;
    right: 20px;
    z-index: 10;
}

.certificatesTable :global(.ant-spin-nested-loading) {
    min-height: 80vh;
}

.certificatesTable :global(.ant-spin.ant-spin-spinning) {
    top: 20%;
}
