.inputItem {
    margin: 0;
}

.input {
    width: 100%;
    color: var(--black);
    border: var(--gap-5xs) solid var(--borderInputIMask);
    padding: var(--gap-3xs) var(--gap-xs-1);
    border-radius: var(--gap-2xs);
}

.input::placeholder {
    color: var(--placeholderInputIMask);
}

.input:disabled {
    cursor: not-allowed;
    color: var(--colorInputI);
}

.input:hover {
    border-color: var(--borderSertificate);
}
