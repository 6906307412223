.enterScreenContainer {
    width: 100%;
}

.text {
    margin: var(--gap-l) auto;
    font-size: var(--medium-x);
    font-weight: var(--font-medium);
}

.textWithLink {
    max-width: var(--text-link-width);
    font-size: var(--small);
    text-align: center;
}

.updateText {
    margin: 0 auto;
    max-width: var(--text-upload-width);
    font-size: var(--small-3);
    text-align: center;
}
