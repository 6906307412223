.header {
    display: flex;
    padding: var(--gap-l) var(--gap-m-1);
    align-items: center;
    justify-content: space-between;
    max-width: var(--width-desktop);
    position: sticky;
    inset: 0;
    backdrop-filter: blur(var(--blur-10));
    z-index: 10;
}

.infoBlock {
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    gap: var(--gap-s);
}

.infoLink {
    color: var(--colorActiveTab);
}

.linkUnderline {
    margin: 0;
    background-color: var(--colorActiveTab);
}

.nameBlock {
    font-size: var(--small);
    line-height: var(--gap-l);
}

.logoutBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--gap-3xs) var(--gap-s);
}

.dropdownMenu {
    cursor: pointer;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.outLined {
    transition: all 0.5s;
}

.show {
    transform: scaleY(-1);
    transition: all 0.5s;
}

.headerButton {
    box-shadow: none;
    margin-left: var(--gap-xs-2);
    border: none;
    font-size: var(--small);
    font-weight: var(--font-medium);
    line-height: var(--gap-l);
}

.headerLink {
    margin-left: var(--gap-xs-2);
}

@media (width <= 500px) {
    .dropdownMenu {
        width: var(--width-for-column-200);
        margin-left: var(--gap-m-1);
    }

    .nameBlock {
        font-size: var(--small-2);
        text-align: right;
    }

    .userOutlined {
        display: none;
    }
}
