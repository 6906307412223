.loader {
    height: 100%;
    min-height: 200px;
    width: clamp(min(40vw, 280px), 380px, 500px);
}

.loader :global(.ant-spin) {
    color: var(--color-text-loader);
}
.loader :global(.ant-spin .ant-spin-dot.ant-spin-dot-spin) {
    top: 25%;
}
.loader.loader :global(.ant-spin .ant-spin-text) {
    font-size: 18px;
    width: 100%;
    padding-top: 0;
}

@media (width <= 500px) {
    .loader {
        width: 320px;
    }
}
