.wrapper {
    display: flex;
    flex-direction: row;
    column-gap: var(--gap-m-1);
    background-color: var(--white);
}

.content {
    background-color: var(--white);
}

.spinner {
    min-height: 80vh;
}

.spinner :global(.ant-spin.ant-spin-spinning) {
    top: 50%;
    transform: translateY(-50%);
}
