.uploadItem {
    display: flex;
    align-items: center;
    gap: var(--gap-3xs);
    margin-bottom: var(--gap-xs-3);
    width: fit-content;
    max-width: var(--grid-template-columns-width-mobile);
}

.uploadItem .downloadButton {
    display: none;
}

.uploadItem:hover .downloadButton {
    display: flex;
    height: 22px;
    width: 22px;
}

.uploadItem:hover {
    background: var(--backgroundDisabled);
}

.removeIcon,
.loadingIcon,
.downloadIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
}
.downloadIcon {
    color: var(--colorActiveTab);
}
.removeIcon {
    color: var(--color-error);
}

.loadingIcon {
    color: var(--colorActiveTab);
}

.fileIcon {
    color: var(--color-black-45);
    height: 22px;
    padding-left: var(--gap-3xs);
}
.fileName {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
