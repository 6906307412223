.title {
    margin-top: var(--gap-xs-2);
    margin-bottom: var(--gap-s);
    font-weight: bold;
    font-size: var(--small-3);
}

.divider {
    margin-bottom: 0;
    margin-top: 0;
}
