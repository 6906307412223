.applicationTabForm {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-bottom: 40px;
}

.applicationTitle {
    color: var(--);
    font-size: var(--medium-l);
    font-weight: var(--font-semi-bold);
}

.applicationTabSubtitle {
    font-size: var(--medium);
    font-weight: var(--font-medium);
}

.applicationTabBlocks {
    display: none;
}

.applicationTabBlocks.active {
    display: grid;
    gap: 20px;
}

.applicationTabBlock {
    padding-top: 24px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 36px 30px;
}

.certificateTemplate {
    margin-bottom: 0;
}

.changeDataNotefication {
    padding: var(--gap-xs-3);
    background: var(--control-item-bg-active);
    font-weight: var(--font-semi-bold);
}

.noteficationText {
    margin-left: var(--gap-3xs-neg);
}

.buttonsRow {
    padding-top: var(--gap-2xl);
}

.icon {
    color: var(--color-error);
    margin-right: var(--gap-m);
    font-size: var(--medium-l);
}

.topArrowIcon {
    margin-right: var(--gap-xs-3);
    font-size: var(--small);
}

.arrowIcon {
    color: var(--borderSertificate);
    margin-right: var(--gap-xs-3);
}

.backButton {
    display: block;
    width: fit-content;
    border: none;
    outline: none;
    padding: 0;
    box-shadow: none;
}

.applicationTabButton {
    font-size: var(--small);
    border-radius: var(--border-radius-s);
    background: var(--backgroundButton);
    color: var(--white);
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
    overflow: hidden;
}

.applicationTabButton:hover {
    background: var(--backgroundButtonHover);
}

.spinner {
    min-height: 80vh;
}

.spinner :global(.ant-spin.ant-spin-spinning) {
    top: 50%;
    transform: translateY(-50%);
}

@media (width <= 400px) {
    .applicationTabBlock {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }

    .applicationTabForm {
        row-gap: 20px;
        margin-bottom: 20px;
    }

    .applicationTabButton {
        width: 100%;
        max-width: var(--button-width-mobile);
    }
}

.answerModal :global(.ant-space) {
    margin-top: 0;
}

.answerModal :global(.ant-modal-close) {
    top: 20px;
    right: 20px;
}
