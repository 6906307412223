.tabs:last-child {
    height: 100%;
}

.tabs :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: var(--colorActiveTab) !important;
}

.tabs :global(.ant-tabs-tab-btn:focus:not(:focus-visible)) {
    color: var(--colorText);
}

.tabs :global(.ant-tabs-content .ant-tabs-tabpane:has(.ant-spin)) {
    min-height: 30vh;
    margin: auto;
}

.tabs :global(.ant-tabs-nav) {
    margin: 0 0 20px;
}
