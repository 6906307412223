.logoImg {
    min-width: 118px;
    min-height: 36px;
    width: 118px;
    height: 36px;
}

.logoImgWhite {
    position: sticky;
    min-width: 118px;
    min-height: 86px;
    width: 118px;
    height: 86px;
}

@media (width <= 500px) {
    .logoImg {
        min-width: 80px;
        min-height: 25px;
        width: 80px;
        height: 25px;
    }

    .logoImgWhite {
        min-width: 118px;
        min-height: 86px;
        width: 118px;
        height: 86px;
    }
}

@media (width <= 400px) {
    .logoImgWhite {
        min-width: 69px;
        min-height: 50px;
        width: 69px;
        height: 50px;
    }
}
