.wrapper {
    width: 100%;
}

.title {
    margin-top: var(--gap-xs-2);
    margin-bottom: var(--gap-s);
}

.warning {
    color: var(--color-error);
    font-weight: var(--font-bold);
    font-size: var(--small-2);
}

.spinner {
    min-height: 80vh;
}

.spinner :global(.ant-spin.ant-spin-spinning) {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
