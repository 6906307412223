.enterPageButton,
.updatePageButton {
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
    box-shadow: none;
    font-size: var(--small);
    outline: var(--outline-white);
    transition: var(--transition-duration-default);
    border: none;
}

.updatePageButtonWhithLink {
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
    box-shadow: none;
    font-size: var(--small);
    outline: var(--outline-white);
    transition: var(--transition-duration-default);
    padding: 0;
    border: 1px solid var(--borderButtonOpacity);
    background-color: var(--white);
}

.updatePageButton {
    background-color: var(--white);
    border: 1px solid var(--borderButtonOpacity);
}

.enterPageButton:hover {
    outline: var(--outline-start-page);
}

.updatePageButton:hover,
.updatePageButtonWhithLink:hover {
    background-color: var(--backgroundDisabled);
    outline: var(--outline-start-page);
}

.enterPageButton:disabled,
.updatePageButton:disabled {
    color: var(--borderButtonOpacity);
    border: 1px solid var(--borderButtonOpacity);
}

.enterPageButtonYes {
    background: var(--PSB_base_orange);
    border-color: var(--PSB_base_orange);
    color: var(--white);
    width: fit-content;
    height: var(--enter-page-button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
}

.applicationTabButton {
    font-size: var(--small);
    border-radius: var(--border-radius-s);
    border: 1px solid var(--PSB_base_orange);
    background: var(--PSB_base_orange);
    color: var(--white);
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
}

.applicationTabButton:hover:enabled,
.enterPageButtonYes:hover:enabled,
.signButton:hover:enabled,
.applicationTabButton:focus:enabled,
.enterPageButtonYes:focus:enabled,
.signButton:focus:enabled {
    background: var(--PSB_base_orangehover) !important;
    border-color: var(--PSB_base_orange) !important;
    color: var(--white) !important;
}
.applicationTabButton:disabled,
.enterPageButtonYes:disabled,
.signButton:disabled {
    background: var(--colorBgContainerDisabled) !important;
    border-color: var(--colorBorderDisabled) !important;
    color: var(--colorTextDisabled) !important;
}

.enterPageButtonYes:hover {
    background: var(--PSB_base_orange);
    border-color: var(--PSB_base_orange);
    color: var(--white);
}

.headerButton {
    box-shadow: none;
    border: none;
    font-size: var(--small);
    font-weight: var(--font-medium);
    line-height: var(--line-height-small-3);
}

@media (width <=400px) {
    .enterPageButton,
    .updatePageButton,
    .updatePageButtonWhithLink {
        width: var(--button-width-mobile);
        font-size: var(--small-1);
    }

    .applicationTabButton {
        width: 100%;
        max-width: var(--button-width-mobile);
    }
}

.signButton {
    border-radius: var(--border-radius-s);
    border: 1px solid var(--PSB_base_orange);
    background: var(--PSB_base_orange);
    color: var(--white);
    margin: 0 auto;
}
