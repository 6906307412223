.select {
    margin-bottom: 0;
    width: 100%;
    min-width: var(--grid-template-columns-width-mobile);
}

.select:disabled {
    cursor: not-allowed;
    color: var(--colorInputI);
}

.icon {
    font-size: var(--small-2);
    color: var(--color-black-45);
    transition: all 0.2s ease;
}
.customLabel {
    align-items: center;
}
