.space {
    margin: var(--gap-xl) auto var(--gap-2xl);
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.spaceButton {
    display: flex;
    justify-content: center;
}

:global(.ant-select).select :global(.ant-select-selector .ant-select-selection-item) {
    text-align: start;
}

.select {
    line-height: var(--line-height-medium);
}
