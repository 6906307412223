.contactUsBlock {
    .contactUsButton {
        position: fixed;
        right: 36px;
        bottom: 32px;
        height: 40px;
        width: 40px;
        background: var(--backgroundButton);
        z-index: 3;

        .contactUsButtonIcon {
            fill: var(--white);
            transition: all 0.2s ease;
        }

        &:hover {
            .contactUsButtonIcon {
                fill: var(--borderSertificate);
            }
        }
    }

    .contactUsMenu {
        background-color: white;
        opacity: 0;
        height: 0;
        position: fixed;
        right: 36px;
        bottom: 80px;
        padding: 0;
        border-radius: var(--border-radius-xs);
        box-shadow:
            0 9px 28px 8px rgba(0, 0, 0, 0.05),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 6px 16px 0 rgba(0, 0, 0, 0.08);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        transition: all 0.2s ease;

        .contactUsItem {
            padding: 10px 16px;
        }

        .contactUsLink {
            .contactUsLinkTitle {
                display: flex;
                gap: 16px;
                align-items: center;

                .title {
                    transition: all 0.2s ease;

                    &:hover {
                        color: var(--borderSertificate);
                    }
                }
            }
        }
    }

    .contactUsMenuOpen {
        padding: 16px 12px;
        opacity: 1;
        height: 330px;
    }
}

.contactUsReviewsButton {
    border: none;
    box-shadow: none;
    display: flex;
    gap: 8px;
    align-items: center;
}
