/* stylelint-disable selector-pseudo-class-no-unknown */
.alighRignt {
    max-width: 50%;
    text-align: right;
}

.alighLeft {
    max-width: 50%;
    text-align: left;
    font-weight: var(--font-semi-bold);
    font-size: var(--small);
}

.modalCollapse :global(.ant-collapse-item > .ant-collapse-header) {
    padding-inline-start: 0;
    background: var(--bg-error-message);
    padding: var(--gap-s) var(--gap-m);
}

.modalCollapse :global(.ant-collapse-item > .ant-collapse-header .ant-collapse-header-text) {
    display: flex;
    justify-content: space-between;
    gap: var(--gap-m);
}

.modalCollapse :global(.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    text-align: start;
}

.modalCollapse {
    width: 100%;
}
