.certificateContainer {
    max-height: var(--max-certificates-height);
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, var(--grid-template-columns-width-big));
    gap: var(--gap-m);
    justify-content: center;
}

.certificateContainer > *:last-child:nth-child(odd) {
    grid-column: 1 / -1;
    justify-self: center;
}

.certificateContainer::-webkit-scrollbar {
    width: 4px;
}

.certificateContainer::-webkit-scrollbar-track {
    background-color: transparent;
}

.certificateContainer::-webkit-scrollbar-button {
    display: none;
}

.certificateContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-black-15);
    border-radius: var(--border-radius-m);
}

/* Стили для скролл-бара (Firefox) */
.certificateContainer {
    scrollbar-width: thin;
    scrollbar-color: var(--color-black-15) transparent;
}

.certificateContainer.notArray {
    grid-template-columns: none;
}
.emptyBlock {
    max-width: var(--empty-block-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-2xl);
}

.chooseCertificateText {
    max-width: var(--text-link-width);
    font-size: var(--small);
    text-align: center;
}

.text {
    font-size: var(--small);
}

@media (width < 850px) {
    .certificateContainer {
        grid-template-columns: repeat(1, var(--grid-template-columns-width-big));
    }
}

@media (width <=400px) {
    .certificateContainer {
        justify-items: center;
        grid-template-columns: repeat(1, 280px);
    }
}
