.reviewModal {
    display: flex;
    align-items: flex-start;

    .reviewModalTextArea {
        width: 470px;
    }

    @media (width <=540px) {
        .reviewModalTextArea {
            width: 83vw;
        }
    }
}
