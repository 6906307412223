.wrapper {
    padding: 0 var(--gap-m-1);
    max-width: var(--width-desktop);
    width: 100%;
    margin: 0 auto;
    background-color: var(--white);
    position: relative;
}

@media (width <= 400px) {
    .wrapper {
        padding: 0 var(--gap-xs-2);
    }
}
